import { Observable } from 'rxjs';
import { SureLcLicense } from 'src/app/shared/models/surelc-license';
import { SureLcProducer } from 'src/app/shared/models/surelc-producer';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SurelcApiService {

  constructor(private http: HttpClient) { }

  getStateLicenses(): Observable<SureLcLicense[]> {
    return this.http.get<SureLcLicense[]>('/api/SureLc/Api/Agent/Licenses');
  }

  getProducer(): Observable<SureLcProducer> {
    return this.http.get<SureLcProducer>('/api/SureLc/Api/Agent');
  }

}
