import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eo-expiration-announcement',
  imports: [
    MatButtonModule,
    DatePipe,
  ],
  templateUrl: './eo-expiration-announcement.component.html',
  styleUrl: './eo-expiration-announcement.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EoExpirationAnnouncementComponent {
  constructor(
    private dialogRef: MatDialogRef<EoExpirationAnnouncementComponent, EOData>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: EOData,
  ) { }

  getQuotes(): void {
    window.open('https://www.ipcginsurance.com/', '_blank');
    this.dialogRef.close();
  }

}


export interface EOData {
  days: number,
  date: Date,
}